<template>
  <!-- 人工申请通道 -->
  <div class="apply_page">
    <van-cell-group class="card_box" inset>
      <div class="reject_reason_text" v-if="verify.apply.status == '审核驳回'">驳回理由：{{ verify.apply.reason }}</div>
      <van-field label="姓名" v-model="name" required readonly />
      <van-field label="身份证号码" v-model="idsn" required readonly />
      <van-field label="预留手机号" v-model="phone" required :readonly="ifReadOnly" />
      <van-field @click="openBankChoose()" label="开户行" v-model="bank_name" required readonly />
      <van-field label="银行卡号" v-model="bank_card" required :readonly="ifReadOnly" />
      <van-field label="上传图片（请上传相关证明材料）" v-model="name" required>
        <template #input>
          <div>
            <div class="picBox">
              <div class="list" v-for="(itm, idx) in preImages" :key="idx">
                <img :src="itm" class="img" @click="show('preImages', idx)" />
                <img v-if="!ifReadOnly" src="/static/clear.png" class="clear" @click="delImg(idx)" />
              </div>
              <div class="list" v-for="(itm, idx) in images" :key="idx">
                <img :src="itm" class="img" @click="show('images', idx)" />
                <img v-if="!ifReadOnly" src="/static/clear.png" class="clear" @click="clear(itm, idx)" />
              </div>
              <template v-if="showAdd && !ifReadOnly">
                <div class="add" @click="chooseImage()" v-if="isWeixin">
                  <img src="/static/upload.png" />
                  <span>添加</span>
                </div>
                <ImageUploader v-else class="upload-img" v-model="images" @change="changeImg" />
              </template>
            </div>
          </div>
        </template>
      </van-field>
    </van-cell-group>
    <div class="btn">
      <van-button round type="info" size="large" class="up" :disabled="disabled" native-type="submit" @click="onClickBtn()">{{ btn_text }}</van-button>
    </div>
    <div v-if="btn_text == '提交' || btn_text == '重新提交'" class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
    <van-popup v-model="showBankList" round position="bottom" :style="{ height: '400px' }" @click-overlay="showBankList = false">
      <van-picker :default-index="findIndex(bankList, bank_name)" show-toolbar :columns="getOption(bankList, bankKeyword)" value-key="value" @cancel="showBankList = false" @confirm="confirmBank">
        <template #title>
          <van-search class="bank_search" v-model="bankKeyword" input-align="left" shape="round" placeholder="搜索开户行名称" @clear="bankKeyword = ''" />
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
// import Upload from '@/components/upload.vue'
import ImageUploader from '@/components/ImageUploader'
import { ImagePreview } from 'vant'
import { realnameApply, DownloadImage, addBankList } from '@/api/api'
import { checkCellphone, bankCardLength } from '@/utils/utils'
import { mapState, mapGetters } from 'vuex'
import { Dialog, Toast } from 'vant'
export default {
  components: {
    ImageUploader
    // Upload
  },
  data() {
    return {
      name: '', //姓名
      idsn: '', //身份证号码
      phone: '', //预留手机号
      bank_name: '', //开户行
      bankList: [], //开户行列表
      bankKeyword: '', // 开户银行关键字
      showBankList: false,
      bank_card: '', //银行卡号
      disabled: false, // 防止重复提交
      btn_text: '', //按钮文字
      fileList: [],
      upload_count: 9, //最大上传数量
      preImages: [],
      images: [],
      ifReadOnly: false //等待审核时只读
    }
  },
  computed: {
    showAdd() {
      return parseInt(this.upload_count) > this.images.length + this.preImages.length
    },
    ...mapGetters(['sub_id']),
    ...mapState(['doctorInfo', 'verify', 'userId', 'doctor_id'])
  },
  mounted() {
    console.log('医生的信息：', this.doctorInfo)
    console.log('verify', this.verify)

    this.init()
  },
  methods: {
    async init() {
      let data = await addBankList({ project_doctor_id: this.doctor_id }) //获取开户行列表
      this.bankList = data.options
      this.name = this.doctorInfo.name
      this.idsn = this.doctorInfo.idsn
      if (this.verify.apply && this.verify.new_apply == 0) {
        this.phone = this.verify.apply.mobile
        this.bank_card = this.verify.apply.bankcard
        this.bank_name = this.verify.apply.bankname
      }

      console.log('apply', this.verify.apply)
      if (this.verify.attachs && this.verify.new_apply == 0) {
        for (let i in this.verify.attachs) {
          console.log('attachs_item', this.verify.attachs[i])
          this.preImages.push(this.verify.attachs[i].filepath)
        }
      }
      this.fileList = this.preImages
      console.log('this.preImages', this.preImages)
      if (this.verify.apply.status == '等待审核') {
        this.ifReadOnly = true
      }
      if (this.verify.apply.length == 0 || this.verify.new_apply == 1) {
        this.btn_text = '提交'
      } else if (this.verify.apply.status == '审核驳回') {
        this.btn_text = '重新提交'
      } else {
        this.btn_text = '返回'
      }
    },
    findIndex(arr, val) {
      for (let i in arr) {
        let ele = arr[i]
        if (ele.name == ele.val) {
          // console.log('findIndex', i)
          this.$forceUpdate
          return i
        }
      }
    },
    getOption(list, val) {
      let arr = list.filter(item => {
        if (item.value) {
          return item.value.indexOf(val) != -1
        } else if (item.name) {
          return item.name.indexOf(val) != -1
        }
      })
      // return val?arr:[]
      return val ? arr : list //输入框为空时显示银行卡列表
    },
    confirmBank(val) {
      if (val) {
        this.bank_name = val.value
        this.showBankList = false
      } else {
        Toast('请先输入关键字查找开户行')
      }
    },
    openBankChoose() {
      if (!this.ifReadOnly) {
        //等待审核时不能修改信息
        this.showBankList = true
      }
    },
    show(status, index) {
      let idx
      if (status == 'images') {
        idx = this.preImages.length + index
      } else {
        idx = index
      }
      let images = this.preImages.concat(this.images)
      this.$nextTick(() => {
        ImagePreview({
          images,
          startPosition: idx
        })
      })
    },
    getImgByteSize(data) {
      let size = 0
      if (data) {
        // 获取base64图片byte大小
        const equalIndex = data.indexOf('=') // 获取=号下标
        if (equalIndex > 0) {
          const str = data.substring(0, equalIndex) // 去除=号
          const strLength = str.length
          const fileLength = strLength - (strLength / 8) * 2 // 真实的图片byte大小
          size = Math.floor(fileLength) // 向下取整
        } else {
          const strLength = data.length
          const fileLength = strLength - (strLength / 8) * 2
          size = Math.floor(fileLength) // 向下取整
        }
      }
      return size * 1.657
    },
    chooseImage() {
      let num = this.upload_count - this.fileList.length
      console.log('num', num)
      // this.$wechat.ready(() => { //苹果手机进不了ready()
      this.$wechat.chooseImage({
        // count: num>9?9:num,
        count: 1,
        // sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: async res => {
          try {
            for (let index = 0; index < res.localIds.length; index++) {
              const serverId = res.localIds[index]

              this.$wechat.getLocalImgData({
                localId: res.localIds[index],
                success: async response => {
                  let localData = response.localData
                  this.picList = response
                  let itemSize = await this.getImgByteSize(localData) //获取base64大小
                  console.log(itemSize, 'itemSize')
                  // alert(itemSize)
                  if (itemSize > 20480000) {
                    Toast('图片过大，请重新选择图片')
                  } else {
                    await this.uploadImage(serverId)
                  }
                }
              })
            }
          } catch (err) {
            console.log(err, 'err')
            Toast(err.response.data.message)
          }
        },
        fail: function () {
          Toast('选图失败')
        }
      })
      // })
    },
    uploadImage(serverId) {
      return new Promise((resolve, reject) => {
        this.$wechat.uploadImage({
          localId: serverId,
          isShowProgressTips: 1,
          success: res => {
            var serverId = res.serverId // 返回图片的服务器端ID
            this.isLoading = true
            DownloadImage({
              params: {
                serverId: serverId
              }
            })
              .then(res => {
                console.log('uploadImage_res:', res)
                this.images.push(res)
                console.log('this.images', this.images)
                let list = this.preImages.concat(this.images)
                console.log('list', list)
                // this.preImages = this.preImages.concat(this.images)
                this.fileList = list
                this.isLoading = false
                this.ocr()
                resolve()
              })
              .catch(err => {
                //  reject(err.response.data.message)
                console.log(err)
                this.isLoading = false
                reject(err)
              })
          }
        })
      })
    },
    clear(index) {
      Dialog.confirm({
        message: '是否确定删除',
        confirmButtonColor: '#4B99FF',
        title: '温馨提示'
      })
        .then(() => {
          this.images.splice(index, 1)
          let list = this.preImages.concat(this.images)
          this.fileList = list
        })
        .catch(err => {
          console.log(err)
        })
    },
    delImg(index) {
      Dialog.confirm({
        message: '是否确定删除',
        confirmButtonColor: '#4B99FF',
        title: '温馨提示'
      })
        .then(() => {
          this.preImages.splice(index, 1)
          let list = this.preImages.concat(this.images)
          this.fileList = list
        })
        .catch(err => {
          console.log(err)
        })
    },
    afterRead(file) {
      //文件读取完成后的回调函数
      console.log('file', file)
      // console.log("fileList",this.fileList)
    },
    async onClickBtn() {
      if (!this.phone) {
        Toast('手机号不能为空')
        return
      } else if (!checkCellphone(this.phone)) {
        Toast('手机号格式有误')
        return
      } else if (!this.bank_card) {
        Toast('银行卡号不能为空')
        return
      } else if (!this.bank_name) {
        Toast('开户行不能为空')
        return
      } else if (!bankCardLength(this.bank_card)) {
        Toast('请输入正确的银行卡号')
        return
      } else if (this.fileList.length == 0) {
        console.log('this.fileList', this.fileList)
        console.log('this.images', this.images)
        console.log('this.preImages', this.preImages)
        Toast('请上传相关证明材料')
        return
      }
      console.log('信息填写完毕', this.fileList.length)
      if (this.btn_text == '返回') {
        history.go(-1)
      } else {
        console.log('this.verify?.apply?.id', this.verify?.apply?.id)
        await realnameApply({
          doctor_id: this.userId,
          subproject_id: this.sub_id,
          mobile: this.phone,
          bankcard: this.bank_card,
          bankname: this.bank_name,
          imgs: this.fileList,
          apply_id: this.verify.new_apply == 1 ? '' : this.verify.apply.id ? this.verify.apply.id : ''
        })
          .then(res => {
            console.log('realnameApply_res:', res)
            Dialog.alert({
              title: '温馨提示',
              message: '信息提交成功',
              confirmButtonColor: '#4B99FF'
            }).then(() => {
              // this.btn_text = '返回'
              history.go(-1)
            })
          })
          .catch(err => {
            console.log('err', err.response.data.message)
            Dialog({
              title: '温馨提示',
              message: err.response.data.message,
              confirmButtonColor: '#4B99FF'
            })
          })
        // 调接口提交表单
      }
    },
    back() {
      history.go(-1)
    },
    changeImg() {
      let list = this.preImages.concat(this.images)
      this.fileList = list
      // this.ocr()
    }
  }
}
</script>

<style lang="scss" scoped>
.apply_page {
  // padding-top: 20px;
  // padding-bottom: 45px;
  padding: 10px 10px 45px;

  .card_box {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
    .van-cell {
      padding: 16px 16px;
      // font-size: 15px;
      ::v-deep .van-field__label {
        font-size: 15px;
      }
    }
    .reject_reason_text {
      color: #ff0000;
      font-size: 15px;
      text-align: left;
      padding: 10px 16px;
      word-break: break-all;
    }
    .picBox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .list {
        height: 50px;
        width: 50px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;

        .img {
          height: 100%;
          width: 100%;
        }

        .clear {
          position: absolute;
          right: -2px;
          top: -2px;
          height: 13px;
          width: 13px;
        }
      }

      .add {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        color: #272727;
        border: 1px dashed #272727;

        img {
          height: 22px;
          width: 22px;
          margin-bottom: 2px;
        }

        span {
          line-height: 12px;
        }
      }
    }
  }
}
</style>
